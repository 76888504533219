<template>
  <div class="container-fluid">
    <div class="row align-items-center justify-content-center" style="height: 80vh">
      <div class="card" style="min-width: 800px;">
        <div class="card-body">
          <div class="d-flex flex-row justify-content-center align-items-center">
            <div class="align-self-center w-50">
              <img :src="require('@/assets/images/undraw/data_processing.svg')" height="256px" class="mx-auto d-block">
            </div>
            <div class="align-self-center w-50">
              <div class="form-group">
                <h3 class="">
                  Account Select
                </h3>
                <p class="">
                  Please enter  <span class="text-warning font-weight-bold"> company name </span> or <span class="text-warning font-weight-bold"> account number </span> below.
                </p>
                <div>
                  <dx-util-select-box
                    :data-source="customerCompanies"
                    :min-search-length="3"
                    :max-item-count="10"
                    :show-clear-button="true"
                    display-expr="companyNameWithAccount"
                    :search-enabled="true"
                    :search-expr="['accountNo', 'name']"
                    search-mode="contains"
                    placeholder="Company Name / Account No"
                    class="my-2 mr-half h4"
                    style="height: 50px;"
                    :defer-rendering="true"
                    :focus-state-enabled="true"
                    @input="debouncedSearchCompany"
                    @contentReady="focusIt"
                    @valueChanged="selectCompany"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable no-unused-vars */
import { Notify } from '@robustshell/utils/index'
import store from '@/store'
import companyService from '@/http/requests/company/companyService'
import {
  onMounted, ref,
} from '@vue/composition-api'
import router from '@/router'
import { useDebounceFn } from '@vueuse/core'
import useCurrentUser from '@/libs/app/current-user'

export default {
  setup() {
    const {
      userAccountNo, userCompanyId, userCompanyName, isIndividualTenant,
    } = useCurrentUser()

    const customerCompanies = ref([])
    const companyOrAccountAutoComplete = ref(null)

    function searchCompany(e) {
      const query = e.component.option('text')
      if (query === '' || query === undefined || query.length < 3) {
        return
      }

      const params = { name: query, type: 'customer' }
      companyService.fetchAllMatchingNameAndType(params).then(response => {
        const data = []
        response.data.forEach(el => {
          data.push({
            companyNameWithAccount: `${el.accountNo} : ${el.name}`, name: el.name, accountNo: el.accountNo, companyId: el.id,
          })
        })
        customerCompanies.value = data
      })
    }

    const debounceDelay = 1000
    const debouncedSearchCompany = useDebounceFn(e => {
      searchCompany(e)
    }, debounceDelay)

    function passIndividualTenantAutomatically() {
      if (isIndividualTenant.value) {
        const accountInfo = {
          accountNo: userAccountNo.value,
          companyId: userCompanyId.value,
          companyName: userCompanyName.value,
        }
        store.dispatch('receiveBatch/setSelectedAccountInfo', accountInfo)
        router.push({
          name: 'route-receive-batch',
          params: {
            accountNo: accountInfo.accountNo, companyId: accountInfo.companyId, storeId: 0, planId: 0,
          },
        }).catch(err => {
          const messeage = err.message || err
          Notify.error(messeage)
        })
      }
    }

    function selectCompany(e) {
      const selectedCompany = e.value
      const accountInfo = {
        accountNo: selectedCompany.accountNo,
        companyId: selectedCompany.companyId,
        companyName: selectedCompany.name,
      }
      store.dispatch('receiveBatch/setSelectedAccountInfo', accountInfo)
      router.push({
        name: 'route-receive-batch',
        params: {
          accountNo: accountInfo.accountNo,
          companyId: accountInfo.companyId,
        },
      }).catch(err => {
        const messeage = err.message || err
        Notify.error(messeage)
      })
    }

    function focusIt(e) {
      e.component.focus()
    }

    onMounted(() => {
      passIndividualTenantAutomatically()
    })

    function checkAccountSelectable(can) {
      if (!can) {
        router.push({ name: 'route-receive-batch' })
      }
    }

    return {
      customerCompanies,
      searchCompany,
      focusIt,
      selectCompany,
      debouncedSearchCompany,
      checkAccountSelectable,
    }
  },
}
</script>
<style lang="scss">
</style>
